<template>
  <div>
    <page-component
      enable-card-view
      listType="cards"
      pageTitle="Menu Items"
      :restaurantFilterAvailable="true"
      :headers="headers"
      :items="filteredMenuItems"
      :buttons="
        userCanEdit
          ? [{ text: '+ Add New Item', to: { params: { form: 'AddEditForm' } } }]
          : []
      "
    >
      <template v-if="menuItemCategories.length" v-slot:filters[0]>
        <custom-select
          v-model="selectedMenuItemCategoryIds"
          :items="menuItemCategories"
          label="Category"
          item-value="id"
          item-text="title"
          multiple
          dense
        />
      </template>
      <template v-slot:filters[1]>
        <custom-select
          v-model="selectedSnoozedStatus"
          :items="snoozedStatusOptions"
          label="Availability"
          multiple
          dense
        />
      </template>
      <template v-slot:card="{ item }">
        <v-img :src="item.itemImage && item.itemImage.url" height="200px"></v-img>
        <v-card-title>
          <v-row>
            <v-col :cols="item.isSnoozed ? 8 : 10" style="word-break: break-word">
              {{ item.name }}
            </v-col>
            <v-col :cols="item.isSnoozed ? 4 : 2" class="text-right pt-0">
              <nxg-snooze-btn
                type="menu item"
                :item="item"
                show-time
                :show-label="false"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-subtitle class="mb-0">
          <v-row>
            <v-col cols="9">
              {{ getRestaurantName(item.restaurantId) }}
              <span
                v-if="item.menuItemCategoryIds && item.menuItemCategoryIds.length"
              >
                &middot; {{ getMenuItemCategoryTitles(item.menuItemCategoryIds) }}
              </span>
            </v-col>
            <v-col cols="3" class="text-right green--text">
              ${{ parseFloat(item.retailPriceMoney.amount / 100).toFixed(2) }}
            </v-col>
          </v-row>
          <v-row v-if="false">
            <v-col cols="12">
              <h4 class="text--disabled">Modifiers:</h4>
              {{ getModifierListNames(item.modifierLists) }}
            </v-col>
          </v-row>
        </v-card-subtitle>
      </template>
      <template v-slot:[`item.retailPrice`]="{ item }">
        <span
          v-if="item.retailPriceMoney"
          :class="{
            'text-decoration-line-through':
              item.promoPriceMoney &&
              item.promoPriceMoney.amount !== item.retailPriceMoney.amount,
          }"
        >
          {{
            parseFloat(item.retailPriceMoney.amount / 100).toFixed(2) +
            " " +
            item.retailPriceMoney.currency
          }}
        </span>
        <span
          v-if="
            item.promoPriceMoney &&
            item.promoPriceMoney.amount !== item.retailPriceMoney.amount
          "
        >
          <br />
          {{
            parseFloat(item.promoPriceMoney.amount / 100).toFixed(2) +
            " " +
            item.promoPriceMoney.currency
          }}
        </span>
      </template>
      <template v-slot:[`item.menuItemCategoryIds`]="{ item }">
        <span v-if="item.menuItemCategoryIds">
          {{ getMenuItemCategoryTitles(item.menuItemCategoryIds) }}
        </span>
      </template>
      <template v-slot:[`item.modifierLists`]="{ item }">
        {{ getModifierListNames(item.modifierLists) }}
      </template>
      <template v-slot:[`item.isSnoozed`]="{ item }">
        <nxg-snooze-btn
          type="menu item"
          :item="item"
          show-time
          :show-label="false"
        />
      </template>
      <template v-if="userCanEdit" v-slot:[`item.actions`]="{ item }">
        <!-- <nxg-action-btn type="report" @click="reportItem(item)" /> -->
        <nxg-action-btn type="edit" @click="editItem(item)" />
        <nxg-action-btn
          v-if="editEnabled(item.restaurantId)"
          type="delete"
          item-type="MENU_ITEM"
          :item="item"
          class="ml-n2"
        />
      </template>
    </page-component>
    <profitability-dialog />
    <add-edit-form
      v-if="$route.params.form == 'AddEditForm' && userCanEdit"
      :supplierItems="supplierItems"
    />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm"
import db from "../../../firebaseConfig"
import { doc, writeBatch } from "firebase/firestore"
import { mapState, mapGetters } from "vuex"
import PageComponent from "../../shared/PageComponent.vue"
import ProfitabilityDialog from "../../shared/dialogs/ProfitabilityDialog.vue"
import NxgSnoozeBtn from "../../shared/SnoozeButton.vue"
import CustomSelect from "../../shared/CustomSelect.vue"

export default {
  name: "menu-items",
  components: {
    AddEditForm,
    PageComponent,
    ProfitabilityDialog,
    NxgSnoozeBtn,
    CustomSelect,
  },
  data() {
    return {
      selectedMenuItemCategoryIds: [],
      selectedSnoozedStatus: [true, false],
      headers: [
        { text: "Name", value: "name" },
        { text: "Category", value: "menuItemCategoryIds" },
        { text: "Modifiers", value: "modifierLists" },
        { text: "Price", value: "retailPrice" },
        { text: "Available", value: "isSnoozed" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      snoozedStatusOptions: [
        {
          text: "Available",
          value: false,
        },
        {
          text: "Unavailable",
          value: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters(["getRestaurantSyncStatus", "getRestaurantName", "userCanEdit"]),
    ...mapState([
      "firebaseRefs",
      "restaurants",
      "supplierItems",
      "modifierLists",
      "menuItems",
      "menuItemCategories",
    ]),

    filteredMenuItems() {
      let items = this.menuItems
      if (
        this.selectedMenuItemCategoryIds.length !== this.menuItemCategories.length
      ) {
        items = items.filter(item =>
          item.menuItemCategoryIds?.some(categoryId =>
            this.selectedMenuItemCategoryIds.includes(categoryId)
          )
        )
      }
      if (!this.selectedSnoozedStatus.length) {
        return []
      }
      return items.filter(item =>
        this.selectedSnoozedStatus.includes(!!item.isSnoozed)
      )
    },
  },
  async mounted() {
    this.setDefaultFilters()
  },
  methods: {
    editEnabled(restaurantId) {
      return !this.getRestaurantSyncStatus(restaurantId)
    },
    async editItem(item) {
      this.$router.push({ params: { form: "AddEditForm", id: item.id } })
    },
    setDefaultFilters() {
      this.selectedMenuItemCategoryIds = this.menuItemCategories.map(cat => cat.id)
    },
    getMenuItemCategoryTitles(categoryIds) {
      if (categoryIds?.length) {
        var nameArray = this.menuItemCategories
          .filter(category => categoryIds.includes(category.id))
          .map(category => category.title)
        if (nameArray?.length < 2) {
          return nameArray.join(", ")
        } else {
          return nameArray.splice(0, 2).join(", ") + " and more"
        }
      } else {
        return "-"
      }
    },
    async checkAndResetElapsedSnoozes() {
      var elapsedSnoozeItems = []
      for (let i = 0; i < this.menuItems.length; i++) {
        if (
          this.menuItems[i].isSnoozed &&
          this.menuItems[i].snoozeUntil > 0 &&
          this.menuItems[i].snoozeUntil <= Date.now()
        ) {
          elapsedSnoozeItems.push(this.menuItems[i])
        }
      }
      if (elapsedSnoozeItems.length > 0) {
        const batch = writeBatch(db)
        for (let i = 0; i < elapsedSnoozeItems.length; i++) {
          var itemRef = doc(this.firebaseRefs.menuitemsRef, elapsedSnoozeItems[i].id)
          batch.update(itemRef, { snoozeUntil: -1, isSnoozed: false })
        }
        await batch.commit()
      }
    },
    getModifierListNames(modifierLists) {
      if (modifierLists?.length) {
        var nameArray = modifierLists.map(modList =>
          this.$store.getters.getModifierListName(modList.modifierListId)
        )
        if (nameArray?.length <= 2) {
          return nameArray.join(", ")
        } else {
          return (
            nameArray.splice(0, 2).join(", ") +
            ", and " +
            nameArray?.length +
            " more"
          )
        }
      } else {
        return "-"
      }
    },
    async reportItem(item) {
      await this.$store.commit("setItemToReport", item)
      this.$router.push({ params: { form: "ProfitabilityDialog", id: item.id } })
    },
  },
}
</script>
<style lang="scss">
.table-card,
.header-style {
  background-color: var(--v-primary-base);
}
</style>
