var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('page-component',{attrs:{"pageTitle":"Menus","restaurantFilterAvailable":true,"headers":_vm.menuHeaders,"items":_vm.filteredMenus,"buttons":_vm.userCanEdit
        ? [
            {
              text: '+ Add New Menu',
              to: { params: { form: 'AddEditForm' } },
            },
          ]
        : []},scopedSlots:_vm._u([{key:`item.restaurantId`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getRestaurantName(item.restaurantId))+" ")]}},{key:`item.items`,fn:function(props){return [_c('v-menu',{attrs:{"open-on-hover":"","offset-x":true,"close-on-content-click":false,"max-height":"400"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('items-list',{attrs:{"content":props.item.menuContent,"menuItemName":props.item.name,"allMenuItems":_vm.menuItems}})],1)]}},{key:`item.categories`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getMenuCategories(item))+" ")]}},(_vm.userCanEdit)?{key:`item.actions`,fn:function({ item }){return [_c('nxg-action-btn',{attrs:{"type":"content-duplicate","confirm":"Are you sure you want to duplicate this menu?"},on:{"click":function($event){return _vm.duplicateMenu(item)}}}),_c('nxg-action-btn',{attrs:{"type":"edit"},on:{"click":function($event){return _vm.editItem(item)}}}),(_vm.editEnabled(item.restaurantId))?_c('nxg-action-btn',{attrs:{"type":"delete","item-type":"MENU","item":item}}):_vm._e()]}}:null],null,true)}),(_vm.$route.params.form === 'AddEditForm' && _vm.userCanEdit)?_c('add-edit-form',{attrs:{"items":_vm.menuItems},on:{"close":function($event){return _vm.$router.push({ params: { form: null, id: null } })}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }