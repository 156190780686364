<template>
  <div>
    <v-sheet class="mx-n4 mt-n4">
      <v-tabs v-model="active" color="text" background-color="primary-darken4">
        <v-tab v-for="tab in tabs" :key="'tab-' + tab.name" :to="{ path: tab.name }">
          {{ tab.label }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="$route.params.tab" touchless>
        <v-tab-item
          v-for="tab in tabs"
          :key="'tab-item-' + tab.name"
          :value="tab.name"
          class="ma-4"
        >
          <component :is="tab.component" v-if="$route.params.tab === tab.name" />
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </div>
</template>

<script>
import MenuItemCategories from "./MenuItemCategories/index.vue"
import MenuItems from "./MenuItems/index.vue"
import Menus from "./Menu/index.vue"
import { mapActions, mapState } from "vuex"

export default {
  components: {
    MenuItems,
    Menus,
    MenuItemCategories,
  },
  data() {
    return {
      active: "items",
    }
  },
  computed: {
    ...mapState(["settings"]),
    tabs() {
      return [
        { name: "items", label: "Menu Items", component: "menu-items" },
        { name: "menus", label: "Menus", component: "menus" },
        {
          name: "categories",
          label: "Categories",
          component: "menu-item-categories",
        },
      ].filter(tab => tab.name !== "categories" || this.menuItemCategoriesEnabled)
    },
    menuItemCategoriesEnabled() {
      return this.settings?.appDisplayOptions?.homeLayout !== "restaurants"
    },
  },
  mounted() {
    this.fetchInventoryData()
  },
  methods: {
    ...mapActions(["fetchInventoryData"]),
  },
}
</script>
