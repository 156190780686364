<template>
  <div>
    <form-dialog
      :visible="$route.params.form == 'AddEditForm'"
      :editMode="editMode"
      title="Menu Item Category"
      :closeDisabled="closeDisabled"
      :awaitingResponse="awaitingResponse"
      :submitDisabled="$v.$anyError"
      :error="mainErrorMessage"
      @close="clearFields"
      @submit="submitHandle"
    >
      <h3 class="my-5">Category Information</h3>
      <v-row>
        <v-col cols="5">
          <v-text-field v-model="title" label="Title" outlined />
        </v-col>
        <v-col cols="5">
          <v-text-field v-model="id" label="ID" outlined />
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="ordinal" label="Ordinal" type="number" outlined />
        </v-col>
      </v-row>
      <h3 class="my-5">Category Image</h3>
      <custom-image-upload
        name="menuItemCategories"
        :imageUrl="imageUrl"
        allow-edit
        showImage
        @imageProcessed="imageUrl = $event"
      />
    </form-dialog>
  </div>
</template>

<script>
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import { required, numeric } from "vuelidate/lib/validators"
import { mapState, mapActions } from "vuex"
import CustomImageUpload from "../../shared/CustomImageUpload.vue"

export default {
  name: "add-edit-form",
  components: { FormDialog, CustomImageUpload },
  validations() {
    return {
      title: { required },
      id: { required },
      ordinal: { numeric },
    }
  },
  data() {
    return {
      title: "",
      id: "",
      imageUrl: "",
      ordinal: 0,
      closeDisabled: false,
      awaitingResponse: false,
      mainErrorMessage: "",
    }
  },
  computed: {
    ...mapState(["firebaseRefs", "menuItemCategories"]),
    editMode() {
      return this.itemToEdit != null
    },
    itemToEdit() {
      return this.$route.params.id
        ? this.menuItemCategories.find(item => item.id == this.$route.params.id)
        : null
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["updateMenuItemCategories"]),
    submitHandle() {
      this.closeDisabled = true
      this.awaitingResponse = true
      this.updateMenuItemCategories({
        id: this.id,
        title: this.title,
        image: { url: this.imageUrl },
        ordinal: this.ordinal,
      })
        .then(() => {
          this.$emit("close")
          this.clearFields()
        })
        .catch(error => {
          this.mainErrorMessage = "Error saving category: " + error.message
        })
        .finally(() => {
          this.closeDisabled = false
          this.awaitingResponse = false
        })
    },
    async prepEdit() {
      this.title = this.itemToEdit.title
      this.id = this.itemToEdit.id
      this.imageUrl = this.itemToEdit.image?.url || ""
      this.ordinal = this.itemToEdit.ordinal
    },
    clearFields() {
      this.$v.$reset()
      this.id = ""
      this.title = ""
      this.imageUrl = ""
      this.ordinal = 0
      this.closeDisabled = this.awaitingResponse = false
      this.mainErrorMessage = ""
      if (this.$route.params.form != null) {
        this.$router.push({ params: { form: null, id: null } })
      }
      this.$emit("close")
    },
  },
}
</script>
