<template>
  <div>
    <page-component
      enable-card-view
      listType="cards"
      pageTitle="Menu Item Categories"
      :headers="headers"
      :items="menuItemCategories"
      :buttons="
        userCanEdit
          ? [{ text: '+ Add New Category', to: { params: { form: 'AddEditForm' } } }]
          : []
      "
    >
      <template v-slot:[`item.image`]="{ item }">
        <v-img
          v-if="item.image && item.image.url"
          :src="item.image.url"
          max-width="150"
          contain
        />
      </template>
      <template v-if="userCanEdit" v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn type="edit" @click="editItem(item)" />
        <nxg-action-btn
          type="delete"
          item-type="MENU_ITEM_CATEGORY"
          :item="item"
          @deleted="fetchMenuItemCategories"
        />
      </template>
      <template v-slot:card="{ item }">
        <v-img
          :src="item.image && item.image.url"
          style="background: var(--v-light-background-base)"
          height="200px"
        ></v-img>
        <v-card-title>
          {{ item.title }}
        </v-card-title>
      </template>
    </page-component>
    <add-edit-form
      v-if="$route.params.form == 'AddEditForm' && userCanEdit"
      @close="fetchMenuItemCategories"
    />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm"
import { mapGetters, mapState, mapActions } from "vuex"
import PageComponent from "../../shared/PageComponent.vue"

export default {
  name: "menu-item-categories",
  components: {
    AddEditForm,
    PageComponent,
  },
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Category Title", value: "title" },
        {
          text: "Category Image",
          value: "image",
          sortable: false,
          align: "center",
          width: 150,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      inventoryItems: [],
      supplierItems: [],
    }
  },
  computed: {
    ...mapState(["firebaseRefs", "menuItemCategories"]),
    ...mapGetters(["userCanEdit"]),
  },
  methods: {
    ...mapActions(["fetchMenuItemCategories"]),
    async editItem(item) {
      this.$router.push({ params: { form: "AddEditForm", id: item.id } })
    },
  },
}
</script>
<style lang="scss">
.table-card,
.header-style {
  background-color: var(--v-primary-base);
}
</style>
