<template>
  <div>
    <page-component
      pageTitle="Menus"
      :restaurantFilterAvailable="true"
      :headers="menuHeaders"
      :items="filteredMenus"
      :buttons="
        userCanEdit
          ? [
              {
                text: '+ Add New Menu',
                to: { params: { form: 'AddEditForm' } },
              },
            ]
          : []
      "
    >
      <template v-slot:[`item.restaurantId`]="{ item }">
        {{ getRestaurantName(item.restaurantId) }}
      </template>
      <template v-slot:[`item.items`]="props">
        <v-menu
          open-on-hover
          :offset-x="true"
          :close-on-content-click="false"
          max-height="400"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
          </template>
          <items-list
            :content="props.item.menuContent"
            :menuItemName="props.item.name"
            :allMenuItems="menuItems"
          />
        </v-menu>
      </template>
      <template v-slot:[`item.categories`]="{ item }">
        {{ getMenuCategories(item) }}
      </template>
      <template v-if="userCanEdit" v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn
          type="content-duplicate"
          confirm="Are you sure you want to duplicate this menu?"
          @click="duplicateMenu(item)"
        />
        <nxg-action-btn type="edit" @click="editItem(item)" />
        <nxg-action-btn
          v-if="editEnabled(item.restaurantId)"
          type="delete"
          item-type="MENU"
          :item="item"
        />
      </template>
    </page-component>
    <add-edit-form
      v-if="$route.params.form === 'AddEditForm' && userCanEdit"
      :items="menuItems"
      @close="$router.push({ params: { form: null, id: null } })"
    />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import ItemsList from "./ItemsPopUp.vue"
import { mapState, mapGetters, mapActions } from "vuex"
import PageComponent from "../../shared/PageComponent.vue"

export default {
  name: "menus",
  components: {
    AddEditForm,
    ItemsList,
    PageComponent,
  },
  data() {
    return {
      selectedMenus: [],
      menuHeaders: [
        { text: "Priority #", value: "priority" },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Restaurant", value: "restaurantId" },
        { text: "Categories", value: "categories" },
        { text: "Items", value: "items" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      searchMenus: "",
      restaurantID: null,
    }
  },
  computed: {
    ...mapGetters(["getRestaurantName", "getRestaurantSyncStatus", "userCanEdit"]),
    ...mapState(["restaurants", "firebaseRefs", "menus", "menuItems"]),
    filteredMenus() {
      return this.menus
        .filter(menu => menu.restaurantId == this.restaurantID || !this.restaurantID)
        .sort((a, b) => (a.priority > b.priority ? 1 : -1))
    },
  },
  created() {
    if (this.restaurants.length === 1) {
      this.restaurantID = this.restaurants[0].id
    }
  },
  methods: {
    ...mapActions(["updateMenus"]),
    editEnabled(restaurantId) {
      return !this.getRestaurantSyncStatus(restaurantId)
    },
    getMenuCategories(item) {
      var categories = []
      item.menuContent.forEach(category => categories.push(category.categoryName))
      return categories.length < 4
        ? categories.join(",  ")
        : categories.slice(0, 3).join(", ") + " and more.."
    },
    async duplicateMenu(menu) {
      var menuToDuplicate = Object.assign({}, menu)
      menuToDuplicate.name = menuToDuplicate.name + " (duplicated)"
      menuToDuplicate.id = null
      this.updateMenus(menuToDuplicate)
    },
    async editItem(item) {
      this.$router.push({ params: { form: "AddEditForm", id: item.id } })
    },
  },
}
</script>

<style></style>
