<template>
  <v-card elevation="12">
    <v-card-title>
      <h4>Menu Items for: {{ menuItemName }}</h4>
    </v-card-title>
    <div v-show="content.length > 0">
      <v-container v-for="(item, i) in content" :key="i">
        <v-card-subtitle class="ml-1">
          <h4>{{ item.categoryName }}</h4>
        </v-card-subtitle>
        <v-card-text v-for="menuItem in item.itemIds" :key="menuItem" class="ml-4">
          - {{ getItemFromID(menuItem) }}
        </v-card-text>
      </v-container>
    </div>
    <div v-show="content.length == 0">
      <v-card-subtitle>No menu items associated with this menu</v-card-subtitle>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "items-list",
  props: {
    content: {
      type: Array,
      required: true,
    },
    menuItemName: {
      type: String,
      required: true,
    },
    allMenuItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getItemFromID(itemID) {
      try {
        return this.allMenuItems.find(item => item.id == itemID).name
      } catch (e) {
        return "Not Found"
      }
    },
  },
}
</script>
<style scoped>
.v-list {
  height: 300px !important;
  overflow-y: auto !important;
  width: 400px !important;
}
</style>
