<template>
  <div v-if="loaded">
    <h4 class="my-5">General ingredients</h4>
    <ingredients v-model="generalIngredients" class="mb-6" @input="handleChange" />
    <div v-for="modList in modifierLists" :key="modList.id">
      <v-flex class="d-flex justify-space-between">
        <h4 class="my-5 d-flex">{{ modList.name }}</h4>
        <v-switch
          v-model="modListHasIngredients[modList.id]"
          class="text-right d-flex"
          @change="changeModListHasIngredients(modList.id)"
        >
          <template #prepend>
            <v-label style="min-width: 180px">
              <small>Modifier-based ingredients</small>
            </v-label>
          </template>
        </v-switch>
      </v-flex>
      <ingredients
        v-for="modItemId in Object.keys(modListIngredients[modList.id])"
        :key="`ingredients-${modList.id}-${modItemId}`"
        v-model="modListIngredients[modList.id][modItemId]"
        :title="`${getModifierItemName(modItemId)} Ingredients`"
        class="mb-6"
        @input="handleChange(modList.id, modItemId)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Ingredients from "@/AuthenticatedContent/shared/forms/Ingredients.vue"
import topLevelMixins from "../../mixins.js"

// This component is used to display a list of ingredients for a given menu item.
// It should be able to also include ingredients that are for a given modifier item AND modifier list.
// General ingredient rows would have no additional data, but modifier item rows would have a modifierListId and modifierItemId.

export default {
  name: "menu-item-ingredients",
  components: { Ingredients },
  mixins: [topLevelMixins],
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    modifierLists: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      loaded: false,
      generalIngredients: [],
      modListHasIngredients: {}, // format: { [modifierListId]: boolean }
      modListIngredients: {}, // format: { [modifierListId]: { [modifierItemId]: [ingredients] } }
    }
  },
  computed: {
    ...mapState(["units", "items", "modifierItems"]),
  },
  watch: {
    value() {
      this.setup()
    },
  },
  mounted() {
    this.setup()
  },
  beforeDestroy() {
    this.recipeIngredients = []
  },
  methods: {
    setup() {
      this.loaded = false
      this.generalIngredients = this.value.filter(
        ingredient => !ingredient.modifierItemId
      )
      this.modListIngredients = {}
      this.modifierLists.forEach(modList => {
        this.modListIngredients[modList.id] = {}
        this.$set(
          this.modListHasIngredients,
          modList.id,
          this.value.some(ingredient => ingredient.modifierListId == modList.id)
        )
        if (this.modListHasIngredients[modList.id]) {
          this.modifierItems
            .filter(item => item.modifierListId == modList.id)
            .forEach(item => {
              const thisItemIngredients = this.value.filter(
                ingredient =>
                  ingredient.modifierListId == modList.id &&
                  ingredient.modifierItemId == item.id
              )
              if (thisItemIngredients.length) {
                this.modListIngredients[modList.id][item.id] = thisItemIngredients
              }
            })
        } else {
          this.modListIngredients[modList.id] = {}
        }
      })
      this.loaded = true
    },
    changeModListHasIngredients(modListId) {
      if (!Object.keys(this.modListIngredients[modListId])?.length) {
        // if there are no ingredients for this modifier list, initialize an
        // empty ingredient for each modifier item
        this.modListIngredients[modListId] = {}
        this.modifierItems
          .filter(item => item.modifierListId == modListId)
          .forEach(item => {
            this.initializeEmptyModifierItemIngredients(modListId, item.id)
          })
      } else {
        // otherwise, clear the ingredients for this modifier list
        this.modListIngredients[modListId] = {}
      }
      this.handleChange()
    },
    initializeEmptyModifierItemIngredients(modListId, modItemId) {
      this.$set(this.modListIngredients[modListId], modItemId, [
        {
          itemID: "",
          measurement: this.createEmptyMeasurementObject(),
          comment: "",
          modifierListId: modListId,
          modifierItemId: modItemId,
        },
      ])
    },
    combineAllIngredientsIntoSingleArray() {
      let allIngredients = []
      allIngredients = allIngredients.concat(this.generalIngredients)
      Object.values(this.modListIngredients)
        // .filter(
        //   modList =>
        //     Object.keys(modList).length &&
        //     Object.values(modList).some(
        //       modItem =>
        //         modItem.length &&
        //         modItem.some(
        //           ingredient => ingredient.itemID && ingredient.measurement.amount
        //         )
        //     )
        // )
        .forEach(modList => {
          Object.values(modList).forEach(modItem => {
            allIngredients = allIngredients.concat(modItem)
          })
        })
      return allIngredients
    },
    getModifierItemName(modItemId) {
      return this.modifierItems.find(item => item.id == modItemId).name || "Unknown"
    },
    handleChange() {
      Object.keys(this.modListIngredients).forEach(modListId => {
        Object.keys(this.modListIngredients[modListId]).forEach(modItemId => {
          this.modListIngredients[modListId][modItemId].forEach(ingredient => {
            ingredient.modifierListId = modListId
            ingredient.modifierItemId = modItemId
          })
        })
      })
      this.$emit("input", this.combineAllIngredientsIntoSingleArray())
    },
  },
}
</script>
