<template>
  <form-dialog
    :visible="$route.params.form == 'AddEditForm'"
    :editMode="editMode"
    title="Menu"
    :closeDisabled="closeDisabled"
    :awaitingResponse="awaitingResponse"
    :error="mainErrorMessage"
    @close="$emit('close')"
    @submit="submitHandle"
  >
    <v-row class="mt-2">
      <v-col cols="6">
        <v-text-field
          v-model="name"
          label="Menu Name"
          :disabled="!editEnabled"
          outlined
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="restaurantID"
          :disabled="!editEnabled"
          :items="restaurants"
          item-text="name"
          item-value="id"
          outlined
          label="Restaurant"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-textarea
          v-model="description"
          :disabled="!editEnabled"
          label="Description (optional):"
          outlined
          height="170"
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="orderType"
          :disabled="!editEnabled"
          :items="orderTypeOptions"
          label="Order Type"
          outlined
          multiple
          class="mb-6"
        />
        <v-text-field
          v-model="priority"
          type="number"
          label="Display Priority"
          :disabled="!editEnabled"
          outlined
        />
        <v-select
          v-if="false"
          v-model="selectedChannels"
          :disabled="!editEnabled"
          :items="channels"
          label="Channels"
          multiple
          outlined
        />
      </v-col>
    </v-row>
    <h3 class="my-5">Hero Image</h3>
    <custom-image-upload
      :allowEdit="editEnabled"
      name="menus"
      :imageUrl="imageUrl"
      @imageProcessed="imageUrl = $event"
    />
    <h3 class="mb-4">Menu Items</h3>
    <v-list two-line>
      <draggable
        :list="menuContent"
        :move="move"
        v-bind="dragOptionsList"
        @change="change"
      >
        <v-list-group
          v-for="(category, i) in menuContent"
          :key="i"
          draggable
          class="my-4"
          style="background-color: var(--v-light-background)"
          no-action
          @start="drag = true"
          @end="drag = false"
        >
          <template v-slot:activator>
            <v-text-field
              v-model="category.categoryName"
              :disabled="!editEnabled"
              class="mt-5"
              prepend-icon="mdi-drag"
              label="Category"
              outlined
            />
            <v-btn
              :disabled="!editEnabled"
              class="ml-6 mb-2"
              icon
              @click="menuContent.splice(i, 1)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <v-list-item-content class="mx-12">
            <v-autocomplete
              v-model="category.itemIds"
              :disabled="!editEnabled"
              class="pr-4"
              outlined
              chips
              clearable
              multiple
              :items="items"
              item-value="id"
              item-text="name"
              label="Select menu items for this category"
            >
              <template v-slot:item="props">
                <v-list-item-avatar size="50">
                  <v-img :src="props.item.itemImage.url" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ props.item.name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ props.item.category }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>

              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click:close="remove(data.item, i)"
                >
                  <v-avatar left>
                    <v-img :src="data.item.itemImage.url" />
                  </v-avatar>
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-list-item-content>
        </v-list-group>
      </draggable>
      <v-list-item>
        <v-list-item-content>
          <v-btn :disabled="!editEnabled" class="my-2" @click="menuContent.push({})">
            + Add New Category
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <h3 class="my-8">Availability</h3>
    <v-row justify="start">
      <v-col cols="12">
        <v-checkbox
          v-model="limitByDate"
          :disabled="!editEnabled"
          label="Available during a limited date range"
        />
      </v-col>
    </v-row>
    <v-flex v-if="limitByDate" d-flex>
      <v-row>
        <v-col cols="12">
          <h4 class="mb-8">Date Range</h4>
        </v-col>
      </v-row>
      <v-row class="my-4">
        <v-col cols="auto">
          <p class="my-4">From</p>
        </v-col>
        <v-col cols="3" class="ml-4">
          <custom-date-picker
            v-model="activeDateRange[0]"
            :disabled="!editEnabled"
            label="Starting date"
            init
          />
        </v-col>
        <v-col cols="auto">
          <p class="my-4">to</p>
        </v-col>
        <v-col cols="3">
          <custom-date-picker
            v-model="activeDateRange[1]"
            :disabled="!editEnabled"
            label="Ending date"
          />
        </v-col>
      </v-row>
    </v-flex>
    <v-row justify="start">
      <v-col cols="12" class="py-0">
        <v-checkbox
          v-model="limitByTimesOfWeek"
          class="mt-0"
          :disabled="!editEnabled"
          label="Available during specific hours of the week"
        />
      </v-col>
    </v-row>
    <v-flex v-if="limitByTimesOfWeek" class="mt-4" d-flex>
      <v-row>
        <v-col cols="12">
          <h4 class="mb-8">Hours</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <weekly-hours v-model="activeTimesOfWeek" :disabled="!editEnabled" />
        </v-col>
      </v-row>
    </v-flex>
  </form-dialog>
</template>

<script>
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import CustomDatePicker from "../../shared/forms/datetime/CustomDatePicker.vue"
import { mapState, mapGetters, mapActions } from "vuex"
import WeeklyHours from "../../shared/forms/datetime/WeeklyHours.vue"
import draggable from "vuedraggable"
import CustomImageUpload from "../../shared/CustomImageUpload.vue"

export default {
  name: "add-edit-form",
  components: {
    FormDialog,
    WeeklyHours,
    CustomDatePicker,
    CustomImageUpload,
    draggable,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      closeDisabled: false,
      awaitingResponse: false,
      description: "",
      restaurantID: "",
      name: "",
      categories: [],
      channels: ["Mobile App", "Uber Eats", "Doordash"],
      selectedChannels: [],
      priority: 0,
      orderType: [],
      heroImg: [],
      imageUrl: "",
      orderTypeOptions: ["Delivery", "Pickup"],
      activeTimesOfWeek: {},
      limitByDate: false,
      limitByTimesOfWeek: false,
      activeDateRange: [],
      menuContent: [],
      drag: false,
      dragged: {
        from: -1,
        to: -1,
        newIndex: -1,
      },
      mainErrorMessage: "",
    }
  },
  computed: {
    ...mapState(["restaurants", "firebaseRefs", "menus"]),
    ...mapGetters(["getRestaurantSyncStatus"]),
    dragOptionsList() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost",
        sort: true,
      }
    },
    itemToEdit() {
      return this.menus?.find(menu => menu.id == this.$route.params.id)
    },
    editMode() {
      return this.itemToEdit != null
    },
    editEnabled() {
      return !this.restaurantID || !this.getRestaurantSyncStatus(this.restaurantID)
    },
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
    menus: {
      handler() {
        if (this.editMode) {
          this.prepEdit()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(["updateMenus"]),
    async submitHandle() {
      this.closeDisabled = this.awaitingResponse = true
      this.updateMenus({
        id: this.itemToEdit?.id,
        menuContent: this.menuContent,
        restaurantId: this.restaurantID,
        name: this.name,
        description: this.description || "",
        channels: this.selectedChannels,
        priority: this.priority || 0,
        orderType: this.orderType || [],
        heroImg: this.imageUrl || "",
        activeDateRanges: this.limitByDate ? this.activeDateRange : [],
        activeTimesOfWeek: this.limitByTimesOfWeek ? this.activeTimesOfWeek : {},
      })
        .then(() => {
          this.$emit("close")
          this.clearFields()
        })
        .catch(error => {
          console.error(error)
          this.mainErrorMessage = "Error saving menu: " + error.message
        })
        .finally(() => {
          this.closeDisabled = false
          this.awaitingResponse = false
        })
    },
    prepEdit() {
      this.id = this.itemToEdit.id
      this.restaurantID = this.itemToEdit.restaurantId
      this.imageUrl = this.itemToEdit.heroImg
      this.name = this.itemToEdit.name
      this.description = this.itemToEdit.description
      this.selectedChannels = this.itemToEdit.channels
      this.priority = this.itemToEdit.priority
      this.orderType = this.itemToEdit.orderType
      this.menuContent = this.itemToEdit.menuContent
      this.activeDateRange = this.itemToEdit.activeDateRanges
      this.limitByDate = this.itemToEdit.activeDateRanges.length > 0
      this.activeTimesOfWeek = this.itemToEdit.activeTimesOfWeek
      this.limitByTimesOfWeek = Object.keys(this.activeTimesOfWeek).length > 0
    },
    async getImage(imageLink) {
      var imageToReturn = await imageLink.getDownloadURL()
      return imageToReturn
    },
    clearFields() {
      this.id = ""
      this.name = ""
      this.description = ""
      this.categories = []
      this.heroImg = []
      this.imageUrl = ""
      this.selectedChannels = []
      this.priority = 0
      this.activeDateRange = []
      this.activeTimesOfWeek = {}
      this.restaurantID = ""
      this.menuContent = []
      this.orderType = ""
      this.awaitingResponse = this.closeDisabled = false
      this.mainErrorMessage = ""
      this.$router.push({ params: { form: null, id: null } })
      this.$emit("close")
    },
    remove(item, indexInMenuContents) {
      const indexInItems = this.menuContent[indexInMenuContents].itemIds.findIndex(
        ID => ID == item.id
      )
      this.menuContent[indexInMenuContents].itemIds.splice(indexInItems, 1)
    },
    move(value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      }
    },
    change(value) {
      if (value.removed) {
        // insert
        this.menuContent.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          this.menuContent[this.dragged.from]
        )
        // delete
        if (this.dragged.from < this.dragged.to)
          // LTR
          this.menuContent.splice(this.dragged.from, 1)
        // RTL
        else this.menuContent.splice(this.dragged.from + 1, 1)
      }
    },
  },
}
</script>
