<template>
  <form-dialog
    :visible="$route.params.form == 'ProfitabilityDialog'"
    :editMode="false"
    :title="title"
    :isCloseDisabled="false"
    :submitDisabled="false"
    :awaitingResponse="false"
    :showFormTitle="false"
    :showSubmit="false"
    @close="$emit('cancel')"
  >
    <v-row class="my-2">
      <v-col cols="6">
        <custom-date-range-filter @change="dates = $event" />
      </v-col>
      <v-col cols="3">
        <v-text-field
          :value="profitMarginFormat"
          label="Target Profit Margin"
          outlined
          append-icon="%"
          @input="setProfitMargin($event)"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="selectedChannel"
          :items="channels"
          item-text="name"
          item-value="id"
          outlined
          label="Select Channel"
        />
      </v-col>
    </v-row>
    <v-apex-charts type="area" :options="chartOptions" :series="formatGraphData" />
    <v-data-table
      :headers="tableHeaders"
      :items="tableData"
      item-key="date"
      sort-by="date"
      show-expand
      single-expand
      :expanded="expanded"
      @item-expanded="getCostHistoryEntry"
    >
      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length">
          <v-row justify="center">
            <v-col cols="8">
              <v-card class="my-2">
                <v-data-table
                  :headers="expandedTableHeaders"
                  :items="costHistoryEntry"
                  hide-default-footer
                >
                  <template slot="body.append">
                    <tr>
                      <th><h2>Total COGS</h2></th>
                      <th>
                        <h2>{{ expandedTotalCogs }}</h2>
                      </th>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:[`footer.page-text`]>
        <vue-json-to-csv :json-data="tableData" :csv-title="title">
          <v-btn icon small><v-icon small>mdi-download</v-icon></v-btn>
        </vue-json-to-csv>
      </template>
    </v-data-table>
  </form-dialog>
</template>

<script>
import FormDialog from "./FormDialog.vue"
import { mapState, mapMutations } from "vuex"
import VueApexCharts from "vue-apexcharts"
import getInventoryItemsLog from "@/mixins/getInventoryItemsLog.js"
import CustomDateRangeFilter from "../forms/datetime/CustomDateRangeFilter.vue"
import VueJsonToCsv from "vue-json-to-csv"

export default {
  name: "profitability-dialog",
  components: {
    FormDialog,
    "v-apex-charts": VueApexCharts,
    CustomDateRangeFilter,
    "vue-json-to-csv": VueJsonToCsv,
  },
  mixins: [getInventoryItemsLog],
  data() {
    return {
      expanded: [],
      inventoryItemsLog: [],
      cogsArray: [],
      xAxisValues: [],
      targetRetailPrice: [],
      actualRetailPrice: [],
      tableHeaders: [
        { text: "Date", value: "date" },
        { text: "COGS", value: "cogs" },
        { text: "Actual Retail Price", value: "actualRetailPrice" },
        { text: "Change Trigger", value: "changeTrigger" },
      ],
      dates: null,
      selectedChannel: "",
      expandedTableHeaders: [
        { text: "Ingredient", value: "name" },
        { text: "Cost", value: "cost" },
      ],
      costHistoryEntry: [],
      expandedTotalCogs: "",
    }
  },
  computed: {
    ...mapState(["itemToReport", "profitMargin", "channels", "items"]),
    title() {
      return this.itemToReport
        ? "Profitability Report for: " + this.itemToReport.name
        : ""
    },
    formatGraphData() {
      return [
        { name: "COGS", data: this.cogsArray },
        {
          name: "Actual Retail Price",
          data: this.actualRetailPrice.map(price => price.actualRetailPrice),
        },
        { name: "Target Retail Price", data: this.targetRetailPrice },
      ]
    },
    tableData() {
      return this.xAxisValues.map((date, i) => {
        return {
          date: date,
          cogs: "$ " + this.cogsArray[i],
          actualRetailPrice: this.actualRetailPrice[i].actualRetailPrice,
          changeTrigger:
            this.actualRetailPrice[i].date == date ? "Actual Retail price" : "COGS",
        }
      })
    },
    chartOptions() {
      return {
        chart: {
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          labels: {
            format: "dd MMM yyyy",
            show: true,
          },
          tickAmount: this.xAxisValues.length,
          categories: this.xAxisValues,
        },
        markers: {
          size: 5,
        },
        tooltip: {
          x: {
            format: "dd/MM/yy",
          },
        },
      }
    },
    profitMarginFormat() {
      return parseFloat(this.profitMargin) * 100
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      async handler(newVal) {
        if (newVal.params.form == "ProfitabilityDialog" && this.dates == null) {
          this.inventoryItemsLog = await this.getInventoryItemsLog()
          this.calculateItemCOGS()
          this.calculateTargetRetailPrice()
          this.calculateActualRetailPrice()
        } else if (this.dates != null) {
          this.filterByDate(this.dates)
        } else {
          this.cogsArray = []
          this.xAxisValues = []
          this.targetRetailPrice = []
          this.actualRetailPrice = []
          this.$store.commit("setItemToReport", null)
        }
      },
    },
    selectedChannel: {
      handler() {
        this.calculateTargetRetailPrice()
      },
    },
    profitMargin: {
      handler() {
        this.calculateTargetRetailPrice()
      },
    },
    dates: {
      immediate: true,
      async handler(newVal) {
        if (newVal || this.dates) {
          this.filterByDate(newVal)
        }
      },
    },
  },
  methods: {
    ...mapMutations(["setProfitMargin"]),
    calculateItemCOGS() {
      this.cogsArray = []
      this.xAxisValues = []
      let ingredientIds = this.itemToReport.ingredients.map(
        ingredient => ingredient.itemID
      )

      let filteredInventoryItemsLog = this.inventoryItemsLog.filter(
        entry =>
          ingredientIds.includes(entry.itemId) &&
          !entry.hasOwnProperty("receivingId")
      )
      let indexOfDate = 0
      for (let i = 0; i < filteredInventoryItemsLog.length; i++) {
        if (
          this.xAxisValues.includes(
            new Date(filteredInventoryItemsLog[i].date).toISOString().split("T")[0]
          )
        ) {
          indexOfDate = this.xAxisValues.indexOf(filteredInventoryItemsLog[i].date)
          this.cogsArray[indexOfDate] +=
            filteredInventoryItemsLog[i].quantity *
            filteredInventoryItemsLog[i].costMoney.amount
        } else {
          this.xAxisValues.push(
            new Date(filteredInventoryItemsLog[i].date).toISOString().split("T")[0]
          )
          this.cogsArray.push(
            (
              filteredInventoryItemsLog[i].quantity *
              filteredInventoryItemsLog[i].costMoney.amount
            ).toFixed(2)
          )
        }
      }
      this.cogsArray = this.cogsArray.filter(value => !Number.isNaN(value))
      this.xAxisValues = this.xAxisValues.sort()
    },
    calculateTargetRetailPrice() {
      this.targetRetailPrice = []
      let channelObj = this.channels.find(
        channel => channel.id == this.selectedChannel
      )
      let channelFlatFee = parseFloat(channelObj.flatFee)
      let channelVariableFee = parseFloat(channelObj.variableFee)
      this.cogsArray.forEach(cogs => {
        this.targetRetailPrice.push(
          (
            (parseFloat(cogs) +
              channelFlatFee +
              channelVariableFee * this.itemToReport.retailPriceMoney.amount) *
            (1 + this.profitMargin)
          ).toFixed(2)
        )
      })
    },
    calculateActualRetailPrice() {
      this.actualRetailPrice = []
      this.actualRetailPrice = this.itemToReport.priceHistory.map(price => {
        return {
          actualRetailPrice: price.actualRetailPrice,
          date: price.creationDate.toDate().toISOString().split("T")[0],
        }
      })
    },
    async filterByDate(dates) {
      this.inventoryItemsLog = await this.getInventoryItemsLog()
      this.inventoryItemsLog = this.inventoryItemsLog.filter(
        entry =>
          new Date(entry.date) >= new Date(dates[0]) &&
          new Date(entry.date) <= new Date(dates[1])
      )
      this.calculateItemCOGS()
      this.calculateTargetRetailPrice()
      this.calculateActualRetailPrice()
    },
    getCostHistoryEntry({ item }) {
      this.costHistoryEntry = []
      let wantedCostEntry = this.itemToReport.costHistory.find(
        costEntry => costEntry.date == item.date
      )
      if (wantedCostEntry) {
        wantedCostEntry.ingredientCost.forEach(itemCost =>
          this.costHistoryEntry.push({
            name: this.items.find(invItem => invItem.itemID == itemCost.itemID).name,
            cost: "$" + itemCost.cost,
          })
        )
      } else {
        this.costHistoryEntry.push("No Data Available")
      }
      this.getTotalCogs(item.date)
    },
    getTotalCogs(date) {
      this.expandedTotalCogs =
        this.itemToReport.costHistory.find(cost => cost.date == date) != undefined
          ? "$" +
            this.itemToReport.costHistory.find(cost => cost.date == date).totalCogs
          : "No Data Available"
    },
  },
}
</script>
