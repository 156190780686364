<template>
  <div>
    <v-flex
      v-for="dayOfWeek in daysOfWeek"
      :id="'dayOfWeek-' + dayOfWeek.id"
      :key="'dayOfWeek-' + dayOfWeek.id"
      d-flex
    >
      <v-row>
        <v-col cols="12" :md="disabled ? 5 : 2">
          <p class="mt-2">{{ dayOfWeek.name }}:</p>
        </v-col>
        <v-col v-if="!disabled" cols="12" md="10">
          <timespan
            v-model="hoursInStringFormat[dayOfWeek.id]"
            :disabled="disabled"
            @input="setHoursForDay(dayOfWeek.id, $event)"
            @copy="copyHoursFromPrevDay(dayOfWeek.id)"
          />
        </v-col>
        <v-col v-else cols="7">
          <p class="mt-2">
            {{ getHoursForDisplay(hoursInStringFormat[dayOfWeek.id]) }}
          </p>
        </v-col>
      </v-row>
    </v-flex>
  </div>
</template>

<script>
import Timespan from "./Timespan.vue"
import datetimeMixins from "./mixins.js"
import moment from "moment"

export default {
  name: "weekly-hours",
  components: {
    Timespan,
  },
  mixins: [datetimeMixins],
  props: {
    value: {
      type: Object,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      daysOfWeek: [
        { name: "Sunday", id: "sun" },
        { name: "Monday", id: "mon" },
        { name: "Tuesday", id: "tue" },
        { name: "Wednesday", id: "wed" },
        { name: "Thursday", id: "thu" },
        { name: "Friday", id: "fri" },
        { name: "Saturday", id: "sat" },
      ],
      hoursInStringFormat: this.getEmptyWeekRange(),
    }
  },
  watch: {
    value: {
      handler() {
        this.updateHours()
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.value || typeof this.value !== "object") {
      this.$emit("input", this.getEmptyWeekRange())
    }
    this.updateHours()
  },
  methods: {
    // timespans are passed in as strings in format "HH:MM" and we need to convert them to number format
    setHoursForDay(dayId, timespans) {
      const updatedTimespanForDay = timespans.map(timespan => {
        return {
          start: this.timeToNumber(timespan.start),
          end: this.timeToNumber(timespan.end),
        }
      })
      this.$emit("input", { ...this.value, [dayId]: updatedTimespanForDay })
    },
    copyHoursFromPrevDay(dayId) {
      const prevDayIndex = this.daysOfWeek.findIndex(day => day.id === dayId)
      if (prevDayIndex === 0) {
        return
      }
      const prevDayId = this.daysOfWeek[prevDayIndex - 1].id
      this.$emit("input", {
        ...this.value,
        [dayId]: this.value[prevDayId],
      })
    },
    updateHours() {
      const formattedHours = this.getEmptyWeekRange()
      if (!this.value || typeof this.value !== "object") {
        return formattedHours
      }
      Object.keys(this.value).forEach(dayId => {
        const timespans = this.value[dayId]
        if (
          timespans &&
          timespans.some(
            timespan =>
              typeof timespan === "object" &&
              "start" in timespan &&
              "end" in timespan
          )
        ) {
          this.$set(
            formattedHours,
            dayId,
            timespans
              .filter(
                timespan =>
                  typeof timespan === "object" &&
                  "start" in timespan &&
                  "end" in timespan
              )
              .map(timespan => {
                return {
                  start: this.numberToTime(timespan.start),
                  end: this.numberToTime(timespan.end),
                }
              })
          )
        }
      })
      this.hoursInStringFormat = formattedHours
    },
    getHoursForDisplay(timespans) {
      if (!timespans || !timespans.length) {
        return "Open all day"
      }
      return timespans
        .map(timespan => {
          if ("start" in timespan && "end" in timespan) {
            if (
              (!timespan.start && !timespan.end) ||
              (timespan.start === "00:00" && timespan.end === "00:00")
            ) {
              return "Closed"
            }
            const startTime = moment(timespan.start, "HH:mm").format("hh:mm A")
            const endTime = moment(timespan.end, "HH:mm").format("hh:mm A")
            return `${startTime} - ${endTime}`
          }
          return "Open 24 hours"
        })
        .join(", ")
    },
  },
}
</script>
