var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('page-component',{attrs:{"enable-card-view":"","listType":"cards","pageTitle":"Menu Items","restaurantFilterAvailable":true,"headers":_vm.headers,"items":_vm.filteredMenuItems,"buttons":_vm.userCanEdit
        ? [{ text: '+ Add New Item', to: { params: { form: 'AddEditForm' } } }]
        : []},scopedSlots:_vm._u([(_vm.menuItemCategories.length)?{key:"filters[0]",fn:function(){return [_c('custom-select',{attrs:{"items":_vm.menuItemCategories,"label":"Category","item-value":"id","item-text":"title","multiple":"","dense":""},model:{value:(_vm.selectedMenuItemCategoryIds),callback:function ($$v) {_vm.selectedMenuItemCategoryIds=$$v},expression:"selectedMenuItemCategoryIds"}})]},proxy:true}:null,{key:"filters[1]",fn:function(){return [_c('custom-select',{attrs:{"items":_vm.snoozedStatusOptions,"label":"Availability","multiple":"","dense":""},model:{value:(_vm.selectedSnoozedStatus),callback:function ($$v) {_vm.selectedSnoozedStatus=$$v},expression:"selectedSnoozedStatus"}})]},proxy:true},{key:"card",fn:function({ item }){return [_c('v-img',{attrs:{"src":item.itemImage && item.itemImage.url,"height":"200px"}}),_c('v-card-title',[_c('v-row',[_c('v-col',{staticStyle:{"word-break":"break-word"},attrs:{"cols":item.isSnoozed ? 8 : 10}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-col',{staticClass:"text-right pt-0",attrs:{"cols":item.isSnoozed ? 4 : 2}},[_c('nxg-snooze-btn',{attrs:{"type":"menu item","item":item,"show-time":"","show-label":false}})],1)],1)],1),_c('v-card-subtitle',{staticClass:"mb-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_vm._v(" "+_vm._s(_vm.getRestaurantName(item.restaurantId))+" "),(item.menuItemCategoryIds && item.menuItemCategoryIds.length)?_c('span',[_vm._v(" · "+_vm._s(_vm.getMenuItemCategoryTitles(item.menuItemCategoryIds))+" ")]):_vm._e()]),_c('v-col',{staticClass:"text-right green--text",attrs:{"cols":"3"}},[_vm._v(" $"+_vm._s(parseFloat(item.retailPriceMoney.amount / 100).toFixed(2))+" ")])],1),(false)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"text--disabled"},[_vm._v("Modifiers:")]),_vm._v(" "+_vm._s(_vm.getModifierListNames(item.modifierLists))+" ")])],1):_vm._e()],1)]}},{key:`item.retailPrice`,fn:function({ item }){return [(item.retailPriceMoney)?_c('span',{class:{
          'text-decoration-line-through':
            item.promoPriceMoney &&
            item.promoPriceMoney.amount !== item.retailPriceMoney.amount,
        }},[_vm._v(" "+_vm._s(parseFloat(item.retailPriceMoney.amount / 100).toFixed(2) + " " + item.retailPriceMoney.currency)+" ")]):_vm._e(),(
          item.promoPriceMoney &&
          item.promoPriceMoney.amount !== item.retailPriceMoney.amount
        )?_c('span',[_c('br'),_vm._v(" "+_vm._s(parseFloat(item.promoPriceMoney.amount / 100).toFixed(2) + " " + item.promoPriceMoney.currency)+" ")]):_vm._e()]}},{key:`item.menuItemCategoryIds`,fn:function({ item }){return [(item.menuItemCategoryIds)?_c('span',[_vm._v(" "+_vm._s(_vm.getMenuItemCategoryTitles(item.menuItemCategoryIds))+" ")]):_vm._e()]}},{key:`item.modifierLists`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getModifierListNames(item.modifierLists))+" ")]}},{key:`item.isSnoozed`,fn:function({ item }){return [_c('nxg-snooze-btn',{attrs:{"type":"menu item","item":item,"show-time":"","show-label":false}})]}},(_vm.userCanEdit)?{key:`item.actions`,fn:function({ item }){return [_c('nxg-action-btn',{attrs:{"type":"edit"},on:{"click":function($event){return _vm.editItem(item)}}}),(_vm.editEnabled(item.restaurantId))?_c('nxg-action-btn',{staticClass:"ml-n2",attrs:{"type":"delete","item-type":"MENU_ITEM","item":item}}):_vm._e()]}}:null],null,true)}),_c('profitability-dialog'),(_vm.$route.params.form == 'AddEditForm' && _vm.userCanEdit)?_c('add-edit-form',{attrs:{"supplierItems":_vm.supplierItems}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }