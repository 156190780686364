<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-select
        v-model="generalAvailability"
        :disabled="disabled"
        :items="generalAvailabilityOptions"
        :color="generalAvailabilityColor"
        :class="generalAvailabilityColor + '-select'"
        outlined
        dense
      />
    </v-col>
    <v-col v-if="generalAvailability === 'specific'" cols="12" md="8">
      <div
        v-for="(hour, i) in hours"
        :key="i"
        class="mb-8 d-flex justify-space-between"
        :class="{ 'mt-n6': i > 0 }"
      >
        <v-text-field
          v-model="hour.start"
          type="time"
          :disabled="disabled"
          outlined
          dense
          hide-details="auto"
          @input="hoursChanged"
        />
        <span class="text-center align-center justify-center mt-2 mx-2">to</span>
        <v-text-field
          v-model="hour.end"
          type="time"
          :disabled="disabled"
          outlined
          dense
          hide-details="auto"
          class="mr-2 flex-grow-1"
          @input="hoursChanged"
        />
        <v-btn
          :disabled="disabled || !hours || hours.length <= 1"
          icon
          @click="removeRow(i)"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <v-btn
          :style="{ opacity: hours && i == hours.length - 1 ? 1 : 0 }"
          :disabled="disabled || !(hours && i == hours.length - 1)"
          icon
          @click="addRow"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import datetimeMixins from "./mixins.js"

export default {
  name: "timespan",
  mixins: [datetimeMixins],
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      hours: this.value,
      generalAvailabilityOptions: [
        { text: "Open All Day", value: "open" },
        { text: "Open for Specific Hours", value: "specific" },
        { text: "Closed All Day", value: "closed" },
        {
          text: "-----------------------",
          value: "",
          disabled: true,
          divider: true,
        },
        { text: "Copy from above", value: "copy" },
      ],
    }
  },
  computed: {
    generalAvailability: {
      get() {
        if (!this.hours || !this.hours.length) {
          return "open"
        } else if (
          this.hours[0].start === "00:00" &&
          this.hours[0].end === "00:00"
        ) {
          return "closed"
        } else {
          return "specific"
        }
      },
      set(value) {
        if (value === "open") {
          this.$emit("input", [])
        } else if (value === "closed") {
          this.$emit("input", [this.getEmptyDateRangeString()])
        } else if (value === "copy") {
          this.$emit("copy", this.hours)
        } else {
          this.$emit("input", [{ start: "00:00", end: "23:59" }])
        }
      },
    },
    generalAvailabilityColor() {
      switch (this.generalAvailability) {
        case "open":
          return "green"
        case "closed":
          return "grey"
        case "specific":
        default:
          return "defaultcolor"
      }
    },
  },
  watch: {
    value: {
      handler(val) {
        this.hours = val
      },
      deep: true,
    },
  },
  methods: {
    addRow() {
      const updatedValue = [...this.hours, { start: "00:00", end: "23:59" }]
      this.$emit("input", updatedValue)
    },
    removeRow(index) {
      const updatedValue = this.hours.filter((_, i) => i !== index)
      this.$emit("input", updatedValue)
    },
    hoursChanged() {
      this.$emit("input", this.hours)
    },
  },
}
</script>
